/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable import/order */
/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useLocation, useNavigate } from "react-router-dom";
import { Book, Command, Edit, Grid, User, Users } from "react-feather";
import "./adminPanelSidebar.scss";

import faqIcon from "../../../assets/icons/clipboard.svg";
import learningPathIcon from "../../../assets/icons/learningPath.svg";
import bookIcon from "../../../assets/icons/book.svg";
import usersIcon from "../../../assets/icons/users.svg";
import wmyprogress from "../../../assets/icons/wmyprogress.svg";
import group from "../../../assets/icons/group.svg";
import iandw from "../../../assets/icons/iandw.svg";
import ap from "../../../assets/icons/ap.svg";
import bap from "../../../assets/icons/bap.svg";
import ai from "../../../assets/icons/ai.svg";
import dashboard from "../../../assets/icons/dashboard.svg";
import member from "../../../assets/icons/member.svg";
import audio from "../../../assets/icons/audio.svg";
import yaudio from "../../../assets/icons/yaudio-img.svg";
import blog2 from "../../../assets/icons/blog2.svg";

//  yellow icon import
import yblog from "../../../assets/icons/yellow/blog.svg";
import ylearningPathIcon from "../../../assets/icons/yellow/Page-1.svg";
import ymyprogressIcon from "../../../assets/icons/myprogress.svg";
import ybookIcon from "../../../assets/icons/yellow/book.svg";
import yusersIcon from "../../../assets/icons/yellow/users.svg";
import ygroup from "../../../assets/icons/yellow/ygroup.svg";
import yiandw from "../../../assets/icons/yellow/iandw.svg";
import ydashboard from "../../../assets/icons/yellow/dashboard.svg";
import ymember from "../../../assets/icons/yellow/member.svg";

import bPrgoress from "../../../assets/icons/blue/progress.svg";
import blearningPath from "../../../assets/icons/blue/learning_path.svg";

import { RootStore, useAppSelector } from "../../../store/store";
import NewSideBarLink from "../NewSideBarLink/NewSideBarLink";
import {
  renderLandingPageManagerNavLinks,
  renderPageManagerNavLinks,
} from "./helper";
import CustomLink from "./CustomLink";
import { ROUTES } from "../../../helpers/utils/Constants/route";
import { TACPrimaryButton } from "../../../shared/Buttons/Buttons";
import useMediaQuery from "../../../hooks/MediaQuery";
import { roles } from "../../../helpers/utils/Constants/roles";
import { TAGS } from "../../../helpers/utils/Constants/tag";
import TacUserSidebar from "./TacUserSidebar";
import SubAdminSidebar from "./SubAdminSidebar";

const AdminPanelSidebar = (props: any) => {
  const {
    smSidebar,
    setSmSidebar,
    showSidebarInSmDevice,
    setShowSidebarInSmDevice,
    mobileSidebarRef,
  } = props;
  const { pathname } = useLocation();
  const smDviceSidbarHanlde = useMediaQuery(820);
  const navigate = useNavigate();

  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });
  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });

  // const [showSidebarInSmDevice, setShowSidebarInSmDevice] = useState(false);
  const CurrentUser: any = localStorage.getItem("tac_user");
  const user: any = JSON.parse(CurrentUser);

  const handleRoute = (route: any) => {
    navigate(route);
  };
  const redirectToLandingBlogPage = () => {
    window.open(`${themeState.configs.site_url}/blog`, "_blank");
  };

  if (smDviceSidbarHanlde) {
    setSmSidebar(false);
  }

  return (
    <>
      <div
        ref={mobileSidebarRef}
        className={`${
          smSidebar
            ? "sidebar__container hide__container"
            : "sidebar__container"
        } ${showSidebarInSmDevice ? "showSidebarInSmDevice" : ""}`}
      >
        <div className="logo__section">
          {!smSidebar && (
            // <img src={logo} className="sidebar__full__logo" alt="" />
            <img
              src={themeState?.configs?.sidebar_open_logo}
              className="sidebar__full__logo"
              alt=""
            />
          )}
          {smSidebar && (
            <img
              src={themeState?.configs?.sidebar_closed_logo}
              className="sidebar__half__logo"
              alt=""
            />
          )}
        </div>
        <div style={{ height: "2px" }} />

        {user?.role === "sys_adm" && (
          <>
            <NewSideBarLink
              img={faqIcon}
              icon={<Grid />}
              title="Landing Page Manager"
              smSidebar={smSidebar}
              links={renderLandingPageManagerNavLinks(
                themeState?.configs?.membership_enabled
              )}
            />
            <NewSideBarLink
              img={faqIcon}
              icon={<Book />}
              title="Course Manager"
              smSidebar={smSidebar}
              links={[
                {
                  id: 1,
                  title: "Learning Resource ",
                  path: "/learning-resource",
                },
                {
                  id: 2,
                  title: "Learning Path",
                  path: "/list-learning-course",
                },
                { id: 3, title: "Manage Courses", path: "/manage-courses" },
              ]}
            />
            {
              themeState?.configs?.membership_enabled &&
              Number(themeState?.configs?.membership_enabled) === 0 &&  (
                <NewSideBarLink
                img={faqIcon}
                icon={<User />}
                title="Individual Members"
                smSidebar={smSidebar}
                links={[
                  { id: 1, title: "Users", path: "/user" },
                  { id: 2, title: "Upcoming Sessions Users", path: "/upcoming-session-users" },
                  { id: 3, title: "Training Group Users", path: "/training-group-users" },
                ]}
              />

              )
            }
               {
              themeState?.configs?.membership_enabled &&
              Number(themeState?.configs?.membership_enabled) === 1 &&  (
                <NewSideBarLink
                img={faqIcon}
                icon={<User />}
                title="Individual Members"
                smSidebar={smSidebar}
                links={[
                  { id: 1, title: "Users", path: "/user" },
                  { id: 2, title: "Subscribers", path: "/subscriber" },
                  { id: 3, title: "Upcoming Sessions Users", path: "/upcoming-session-users" },
                  { id: 4, title: "Training Group Users", path: "/training-group-users" },
                ]}
              />

              )
            }
         
            {themeState?.configs?.membership_enabled &&
              Number(themeState?.configs?.membership_enabled) === 0 && (
                <NewSideBarLink
                  img={faqIcon}
                  icon={<Command />}
                  title="User Dashboard "
                  smSidebar={smSidebar}
                  links={[
                    { id: 1, title: "Onboarding ", path: "/onboarding" },
                    { id: 2, title: "Sprint", path: "/sprint" },
                    {
                      id: 3,
                      title: "Quick Link and resources",
                      path: "/quicklinks",
                    },
                    { id: 4, title: "Archive", path: "/archive" },
                    { id: 5, title: "Reference", path: "/reference" },
                    {
                      id: 6,
                      title: "Interview",
                      path: `${ROUTES.INTERVIEW}/${TAGS.RECENTLY_ANSWERED}`,
                    },
                    { id: 7, title: "Meet With Us", path: "/meet-with-us" },
                    { id: 8, title: "Onboarding Video", path: `${ROUTES.VIDEO_TOUR}` },
                  ]}
                />
              )}

            {themeState?.configs?.membership_enabled &&
              Number(themeState?.configs?.membership_enabled) === 1 && (
                <NewSideBarLink
                  img={faqIcon}
                  icon={<Users />}
                  title="Team Training"
                  smSidebar={smSidebar}
                  links={[
                    { id: 1, title: "Teams ", path: "/tenant-teams" },
                    { id: 2, title: "Payment plans", path: "/payment-plans" },
                  ]}
                />
              )}
            <NewSideBarLink
              img={faqIcon}
              icon={<Edit />}
              title="Page Manager"
              smSidebar={smSidebar}
              links={renderPageManagerNavLinks(
                themeState?.configs?.membership_enabled
              )}
            />
          </>
        )}

        {user?.role === roles.SUB_ADMIN && (
          <SubAdminSidebar smSidebar={smSidebar} />
        )}

        {(user?.role === "owner" || user?.role === "admin") && (
          <>
            <CustomLink
              title="Dashboard"
              route="/organization"
              img={dashboard}
              yimg={ydashboard}
              smSidebar={smSidebar}
            />
            {/* <CustomLink
              title="Groups"
              route="/groups"
              img={group}
              yimg={ygroup}
              smSidebar={smSidebar}
            /> */}
            {!pathname.includes("groups") && (
              <CustomLink
                title="Groups"
                route="/groups"
                img={group}
                yimg={ygroup}
                smSidebar={smSidebar}
              />
            )}
            {pathname.includes("groups") && (
              <CustomLink
                title="Groups"
                route="/groups"
                img={ygroup}
                yimg={ygroup}
                smSidebar={smSidebar}
                active
              />
            )}

            {!pathname.includes("members") && (
              <CustomLink
                title="Members"
                route="/members"
                img={member}
                yimg={ymember}
                smSidebar={smSidebar}
              />
            )}
            {pathname.includes("members") && (
              <CustomLink
                title="Members"
                route="/members"
                img={ymember}
                yimg={ymember}
                smSidebar={smSidebar}
                active
              />
            )}
            {/* <CustomLink
              title="Members"
              route="/members"
              img={member}
              yimg={ymember}
              smSidebar={smSidebar}
            /> */}
            {/* <CustomLink
              title="Manage Assignment"
              route="/manage-assignments"
              img={usersIcon}
              yimg={yusersIcon}
            /> */}

            {!pathname.includes("assignments") && (
              <CustomLink
                title="Manage Assignment"
                route="/manage-assignments"
                img={usersIcon}
                yimg={yusersIcon}
                smSidebar={smSidebar}
              />
            )}
            {pathname.includes("assignments") && (
              <CustomLink
                title="Manage Assignment"
                route="/manage-assignments"
                img={yusersIcon}
                yimg={yusersIcon}
                smSidebar={smSidebar}
                active
              />
            )}

            {!pathname.includes("learningpath") && (
              <CustomLink
                title="Learning Paths"
                route="/admin-learningpath"
                img={learningPathIcon}
                yimg={ylearningPathIcon}
                smSidebar={smSidebar}
              />
            )}
            {pathname.includes("learningpath") && (
              <CustomLink
                title="Learning Paths"
                route="/admin-learningpath"
                img={ylearningPathIcon}
                yimg={ylearningPathIcon}
                smSidebar={smSidebar}
                active
              />
            )}
            {!pathname.includes("courses") && (
              <CustomLink
                title="Courses"
                route="/admin-courses"
                img={bookIcon}
                yimg={ybookIcon}
                smSidebar={smSidebar}
              />
            )}
            {pathname.includes("courses") && (
              <CustomLink
                title="Courses"
                route="/admin-courses"
                img={ybookIcon}
                yimg={ybookIcon}
                smSidebar={smSidebar}
                active
              />
            )}
            {/* <CustomLink
              title="Courses"
              route="/admin-courses"
              img={bookIcon}
              yimg={ybookIcon}
              smSidebar={smSidebar}
            /> */}
            <CustomLink
              title="Interviews and Webinars"
              route="/interviews-webinars"
              img={iandw}
              yimg={yiandw}
              smSidebar={smSidebar}
            />
            <CustomLink
              title="Audio Podcast"
              route="/audio-podcasts"
              img={audio}
              yimg={yaudio}
              smSidebar={smSidebar}
            />
            <CustomLink
              title="Blogs"
              route="/blogs"
              img={blog2}
              yimg={yblog}
              smSidebar={smSidebar}
              onClick={redirectToLandingBlogPage}
            />
          </>
        )}

        {user?.role === "student" && (
          <>
            {!pathname.includes("organization-user-dashboard") && (
              <CustomLink
                title="Dashboard"
                route="/organization-user-dashboard"
                img={wmyprogress}
                yimg={ymyprogressIcon}
                smSidebar={smSidebar}
              />
            )}
            {pathname.includes("organization-user-dashboard") && (
              <CustomLink
                title="Dashboard"
                route="/organization-user-dashboard"
                img={ymyprogressIcon}
                yimg={ymyprogressIcon}
                smSidebar={smSidebar}
                active
              />
            )}

            {!pathname.includes("organization-user-learningpath") && (
              <CustomLink
                title="Learning Paths"
                route="/organization-user-learningpath"
                img={learningPathIcon}
                yimg={ylearningPathIcon}
                smSidebar={smSidebar}
              />
            )}
            {pathname.includes("organization-user-learningpath") && (
              <CustomLink
                title="Learning Paths"
                route="/organization-user-learningpath"
                img={ylearningPathIcon}
                yimg={ylearningPathIcon}
                smSidebar={smSidebar}
                active
              />
            )}

            {!pathname.includes("organization-user-courses") && (
              <CustomLink
                title="Courses"
                route="/organization-user-courses"
                img={bookIcon}
                yimg={ybookIcon}
                smSidebar={smSidebar}
              />
            )}
            {pathname.includes("organization-user-courses") && (
              <CustomLink
                title="Courses"
                route="/organization-user-courses"
                img={ybookIcon}
                yimg={ybookIcon}
                smSidebar={smSidebar}
                active
              />
            )}
          </>
        )}
        {user?.role === "user" && (
          <div className="tac__user__sidebar__wrapper">
          <TacUserSidebar
            smSidebar={smSidebar}
            redirectToLandingBlogPage={redirectToLandingBlogPage}
          />
          </div>
        )}

        {user?.role === roles.PRODUCT_MANAGER_STUDENT && (
          <>
            {!pathname.includes("progress") && (
              <CustomLink
                title="My Progress"
                route="/progress"
                img={wmyprogress}
                yimg={bPrgoress}
                smSidebar={smSidebar}
              />
            )}
            {pathname.includes("progress") && (
              <CustomLink
                title="My Progress"
                route="/progress"
                img={bPrgoress}
                yimg={bPrgoress}
                smSidebar={smSidebar}
                active
              />
            )}

            <CustomLink
              title="Learning Path"
              route={ROUTES.PRODUCT_MANAGER_COURSES}
              img={learningPathIcon}
              yimg={blearningPath}
              smSidebar={smSidebar}
            />

            <CustomLink
              title="Audio Podcast"
              route="/audio-podcast"
              img={ap}
              yimg={bap}
              smSidebar={smSidebar}
            />
            <CustomLink
              title="AI-Assist"
              route="/ai-assist"
              img={ai}
              yimg={ai}
              smSidebar={smSidebar}
            />
            {!smSidebar && authState.tac_user?.plan === "free" && (
              <div className="upgrade__button__container">
                <TACPrimaryButton
                  text="Upgrade"
                  onClick={() => navigate("/accounts/subscriptions")}
                />{" "}
              </div>
            )}
          </>
        )}

        {/* <span
          className={`sidebar__divider__title mt-1 mb-1 ${
            smSidebar ? "hide" : " "
          }`}
        >
          Page Manager
        </span>
        <CustomLink
          title="Reference"
          route="/reference"
          smSidebar={smSidebar}
          img={faqIcon}
          yimg={yfaqIcon}
        />
        <CustomLink
          title="Quick links and resources "
          route="/quicklinks"
          smSidebar={smSidebar}
          img={faqIcon}
          yimg={yfaqIcon}
        /> */}
      </div>
      {/* <div> */}
      {/* <div
          className={`smMenu ${
            showSidebarInSmDevice ? "showSidebarInSmDevice" : ""
          }`}
        >
          <img
            src={themeState.themeMode === "dark" ? blackMenu : whiteMenu}
            className="menu__button"
            onClick={() => setShowSidebarInSmDevice(!showSidebarInSmDevice)}
            alt="menu"
          />
        </div> */}
      {/* </div> */}
    </>
  );
};

export default AdminPanelSidebar;
